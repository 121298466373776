import { useState } from 'react';
import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ListItemText from '@mui/material/ListItemText';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Stack, Button, Tooltip, Typography } from '@mui/material';

import { USDT } from 'src/hooks/use-USDT';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import { fDate, fTime } from 'src/utils/format-time';

import Label from 'src/components/label/label';
import TextMaxLine from 'src/components/text-max-line/text-max-line';

import WithdrawalRequestApprove from './approveWithDrawal/ApproveRequest';
import WithdrawalRequestDetails from './approveWithDrawal/withdrawalRequistDetails';

// ----------------------------------------------------------------------

export default function withdrawalRequestTableRow({ row, selected, memberSingle }) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isOpen, setIsOpen] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isViewOpen, setIsViewOpen] = useState(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isOpenModal, setIsOpenModal] = useState(false);

  const {
    createdAt,
    amount,
    remarks,
    request_id,
    cancelled_at,
    status,
    approved_at,
    deduction_amount,

    member,
  } = row;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const role = useGetRoles()?.role;
  const getColor = () => {
    switch (status) {
      case 'PROCESSING':
        return 'warning';
      case 'APPROVED':
        return 'success';
      case 'IN_PROGRESS':
        return 'info';
      case 'CANCELLED':
        return 'error';
      default:
        return 'default';
    }
  };

  const handleStatusValues = () => {
    switch (status) {
      case 'PROCESSING':
        return 'Processing';
      case 'APPROVED':
        return 'Approved';
      case 'IN_PROGRESS':
        return 'In Progress';
      case 'CANCELLED':
        return 'Cancelled';
      default:
        return 'default';
    }
  };

  return (
    <>
      {role === 'admin' && (
        <WithdrawalRequestApprove open={isOpen} setOpen={setIsOpen} isUpdate={row} />
      )}
      <WithdrawalRequestApprove
        open={isViewOpen}
        setOpen={setIsViewOpen}
        isUpdate={row}
        view="view"
      />
      {role === 'admin' && (
        <WithdrawalRequestDetails open={isOpenModal} setOpen={setIsOpenModal} isUpdate={row} />
      )}
      <TableRow hover selected={selected}>
        <TableCell>
          <ListItemText
            primary={fDate(createdAt)}
            secondary={fTime(createdAt)}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>

        <TableCell>{request_id}</TableCell>
        {role === 'admin' && !memberSingle && (
          <TableCell>
            {' '}
            <ListItemText
              primary={`${member?.name}`}
              secondary={`${member?.member_id}`}
              primaryTypographyProps={{ typography: 'body2', noWrap: true }}
              secondaryTypographyProps={{
                mt: 0.5,
                component: 'span',
                typography: 'caption',
              }}
            />
          </TableCell>
        )}
        <TableCell sx={{ wordWrap: 'break-word' }}>
          {role === 'admin' ? (
            <TextMaxLine line={2}>
              <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
                {amount.toFixed(2)} {USDT}
              </Typography>
            </TextMaxLine>
          ) : (
            <ListItemText
              primary={`${amount.toFixed(2)} ${USDT}`}
              // secondary={`$${deduction_amount} deduction`}
              secondary={`${deduction_amount.toFixed(2)} ${USDT} deduction`}
              primaryTypographyProps={{ variant: 'body2', noWrap: true }} // Changed typography to variant
              secondaryTypographyProps={{
                mt: 0.5,
                component: 'span',
                variant: 'caption', // Changed typography to variant
              }}
            />
          )}
        </TableCell>
        {role === 'admin' && (
          <TableCell variant="subtitle2" sx={{ wordWrap: 'break-word' }}>
            <TextMaxLine line={2}>
              <Typography variant="subtitle2">
                {deduction_amount.toFixed(2)} {USDT}
              </Typography>
            </TextMaxLine>
          </TableCell>
        )}
        {role === 'admin' && (
          <TableCell>
            <TextMaxLine line={2} variant="body2" sx={{ maxWidth: 300 }}>
              {remarks || '--'}
            </TextMaxLine>
          </TableCell>
        )}
        {role === 'admin' && (
          <TableCell>
            <Label variant="soft" color={getColor()}>
              {handleStatusValues()}
            </Label>
          </TableCell>
        )}
        {/* <TableCell>{status}</TableCell> */}
        <TableCell>
          {role !== 'admin' ? (
            // in case of member
            <Label variant="soft" color={getColor()}>
              {/* {status} */}
              {status === 'PROCESSING' && 'Processing'}
              {status === 'APPROVED' && 'Approved'}
              {status === 'IN_PROGRESS' && 'In Progress'}
              {status === 'CANCELLED' && 'Cancelled'}
            </Label>
          ) : (
            <>
              {row?.status === 'PROCESSING' && (
                // <ButtonGroup
                //   disableElevation
                //   variant="contained"
                //   aria-label="Disabled button group"
                // >
                //   <Button size="small" color="error" onClick={() => setIsOpen(true)}>
                //     Reject
                //   </Button>
                //   <Button color="success" size="small" onClick={() => setIsOpen(true)}>
                //     Approve
                //   </Button>
                // </ButtonGroup>
                <Button
                  size="small"
                  variant="contained"
                  color="info"
                  onClick={() => setIsOpen(true)}
                >
                  View
                </Button>
              )}

              {row?.status === 'IN_PROGRESS' && (
                // <ButtonGroup
                //   disableElevation
                //   variant="contained"
                //   aria-label="Disabled button group"
                // >
                //   <Button size="small" color="error" onClick={() => setIsOpen(true)}>
                //     Reject
                //   </Button>
                //   <Button color="success" size="small" onClick={() => setIsOpen(true)}>
                //     Approve
                //   </Button>
                // </ButtonGroup>
                <Button
                  size="small"
                  variant="contained"
                  color="info"
                  onClick={() => setIsOpen(true)}
                >
                  View
                </Button>
              )}
              {row?.status === 'APPROVED' && (
                <Stack flexDirection="row" alignItems="center">
                  <ListItemText
                    primary={fDate(approved_at)}
                    secondary="Approved At"
                    primaryTypographyProps={{ variant: 'body2', noWrap: true }}
                    secondaryTypographyProps={{
                      mt: 0.5,
                      component: 'span',
                      variant: 'caption',
                    }}
                  />
                  <Tooltip title="View Details" onClick={() => setIsViewOpen(true)}>
                    <VisibilityIcon sx={{ fontSize: '20px', cursor: 'pointer', ml: 2 }} />
                  </Tooltip>
                </Stack>
              )}
              {row?.status === 'CANCELLED' && (
                <Stack flexDirection="row" alignItems="center">
                  <ListItemText
                    primary={fDate(cancelled_at)}
                    secondary="Cancelled At"
                    primaryTypographyProps={{ variant: 'body2', noWrap: true }}
                    secondaryTypographyProps={{
                      mt: 0.5,
                      component: 'span',
                      variant: 'caption',
                    }}
                  />
                  <Tooltip
                    title="View Details"
                    onClick={() => setIsViewOpen(true)}

                    // onClick={() => setIsOpenModal(true)}
                  >
                    <VisibilityIcon sx={{ fontSize: '20px', cursor: 'pointer', ml: 2 }} />
                  </Tooltip>
                </Stack>
              )}
            </>
          )}
        </TableCell>
        {role !== 'admin' && (
          <TableCell>
            <Stack flexDirection="row" alignItems="center">
              <Tooltip
                title="View Details"
                onClick={() => setIsViewOpen(true)}

                // onClick={() => setIsOpenModal(true)}
              >
                <VisibilityIcon sx={{ fontSize: '20px', cursor: 'pointer', ml: 2 }} />
              </Tooltip>
            </Stack>
          </TableCell>
        )}
      </TableRow>
    </>
  );
}

withdrawalRequestTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  memberSingle: PropTypes.bool,
};
