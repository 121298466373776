/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { Paper, TableRow, TableCell, CircularProgress } from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import { getAllMembers } from 'src/server/api/member';
import { getAllTransactionsAdmin } from 'src/server/api/transaction';

import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import TransactionToolbar from 'src/sections/transaction/TransactionToolbar';
import TransactionTableRow from 'src/sections/transaction/TransactionTableRow';

// ----------------------------------------------------------------------

export default function TransactionMemberSingleView({ memberSingle, filterMember }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const router = useRouter();
  const table = useTable({ defaultOrderBy: 'createdAt', defaultRowsPerPage: 5 });
  const confirm = useBoolean();
  const role = useGetRoles()?.role;
  const [selectedWalletType, setSelectedWalletType] = useState('');

  // console.log('category =>', selectedCategory);

  const [selectedmember, setSelectedmember] = useState();
  const [searchmember, setSearchmember] = useState('');

  const handleTextFieldChangeMember = (event) => {
    setSearchmember(event.target.value);
    setPage();
  };
  const handleAutocompleteChangeMember = (e, newValue) => {
    setSelectedmember(newValue || '');
    setPage();
  };

  const { loading, transactionList, membersList, MemberProfileDetails } = useSelector((state) => ({
    loading: state.transaction.loading,
    transactionList: state.transaction.transactions,
    membersList: state.member.members?.members,
    MemberProfileDetails: state.member.memberProfileDetails.member,
  }));
  // console.log(transactionList);
  const identifier = 'transaction';
  const storedRowsPerPage = sessionStorage.getItem(`rowsPerPage_${identifier}`);

  const [searchText, setSearchText] = useState();
  // const [sort, setSort] = useState('');
  const [date, setDate] = useState('');
  // console.log(setSort);
  const [tableData, setTableData] = useState([]);
  const totalPage = transactionList?.total;

  const TABLE_HEAD =
    role === 'admin'
      ? [
          { id: 'date', label: 'Date' },
          // { id: 'member', label: 'Member' },
          ...(memberSingle ? [] : [{ id: 'name', label: 'Member' }]),

          { id: 'description', label: 'Description' },
          // { id: 'remarks', label: 'Remarks' },
          { id: 'wallet', label: 'Wallet Type' },
          { id: 'credit', label: 'Credit' },
          { id: 'debit', label: 'Debit' },
        ]
      : [
          { id: 'date', label: 'Date' },
          { id: 'description', label: 'Description' },
          { id: 'wallet', label: 'Wallet' },
          { id: 'credit', label: 'Credit' },
          { id: 'debit', label: 'Debit' },
        ];
  // console.log(memberSingle);
  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      limit: storedRowsPerPage || table.rowsPerPage,
      transaction_type: '',
      date,
      dispatch,
      member: memberSingle || selectedmember?.value,
      wallet_type: selectedWalletType?.value,
    };

    dispatch(getAllTransactionsAdmin(credentials));
  }, [
    date,
    dispatch,
    role,
    searchText,
    storedRowsPerPage,
    table.page,
    table.rowsPerPage,
    selectedmember,
    selectedWalletType,
    MemberProfileDetails?._id,
    memberSingle,
  ]);

  useEffect(() => {
    setTableData(transactionList?.walletTransactions);
  }, [transactionList]);

  const dataInPage = [];

  const canReset = false;

  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData?.filter((row) => row.id !== id);
      enqueueSnackbar('Delete success!');
      setTableData(deleteRow);
      table.onUpdatePageDeleteRow(dataInPage?.length);
    },
    [dataInPage?.length, enqueueSnackbar, table, tableData]
  );

  const handleDeleteRows = () => {};

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.edit(id));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.details(id));
    },
    [router]
  );

  const handleSearch = (value) => {
    setSearchText(value);
  };

  useEffect(() => {
    if (filterMember) {
      // eslint-disable-next-line no-unused-expressions
      ('');
    } else {
      const credentials = {
        page: '',
        search: searchmember,
        limit: '',
        sort: '',
        date: '',
      };
      if (role === 'admin') {
        dispatch(getAllMembers(credentials));
      }
    }
  }, [role, searchmember, dispatch, filterMember]);

  const optionMember = [
    { label: 'All', value: '' },
    ...(membersList || []).map((results) => ({
      label: results?.name,
      value: results?._id,
    })),
  ];

  const wallet_type = [
    { label: 'Catalyst', value: 'CATALYST' },
    { label: 'Company', value: 'COMPANY' },
    { label: 'Club', value: 'CLUB' },
    { label: 'Connect', value: 'CONNECT' },
    { label: 'Member', value: 'MEMBER' },
    { label: 'Maintenance', value: 'MAINTENANCE' },
    { label: 'Fourth', value: 'FOURTH' },
    { label: 'Incentive', value: 'INCENTIVE' },
  ];

  const optionWalletType = [
    { label: 'All', value: '' },
    ...(wallet_type || []).map((results) => ({
      label: results?.label,
      value: results?.value,
    })),
  ];

  const handleAutocompleteChangeWalletType = (e, newValue) => {
    setSelectedWalletType(newValue || '');
    setPage();
  };

  const setPage = () => {
    table.setPage(0);
  };
  return (
    <>
      <Stack width="100%">
        <Card>
          <Stack mx={2} my={3}>
            <TransactionToolbar
              role={role}
              walletTypeSelect={handleAutocompleteChangeWalletType}
              walletTypeOption={optionWalletType}
              onSearchMember={handleTextFieldChangeMember}
              onChange={handleAutocompleteChangeMember}
              memberOption={optionMember}
              onSearch={handleSearch}
              date={date}
              setDate={setDate}
              memberSingle={memberSingle}
              setPage={setPage}
            />
          </Stack>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) => {
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                );
              }}
            />
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={table.selected?.length}
                  onSort={table.onSort}
                />

                {loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress color="inherit" />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                <TableBody>
                  {(tableData || []).map((row, index) => (
                    <TransactionTableRow
                      index={index}
                      page={table.page + 1}
                      rowsPerPage={storedRowsPerPage || table.rowsPerPage || 25}
                      key={row.id}
                      row={row}
                      selected={table?.selected?.includes(row.id)}
                      onSelectRow={() => table.onSelectRow(row.id)}
                      onViewRow={() => handleViewRow(row.id)}
                      onEditRow={() => handleEditRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      loading={loading}
                      colSpan={TABLE_HEAD?.length}
                      memberSingle={memberSingle}
                    />
                  ))}
                </TableBody>

                {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                />  */}

                <TableNoData notFound={notFound} />
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={totalPage || 0}
            customRowsPerPage={5}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
            identifier={identifier}
          />
        </Card>
      </Stack>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

TransactionMemberSingleView.propTypes = {
  memberSingle: PropTypes.string,
  filterMember: PropTypes.bool,
};
