/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { Paper, TableRow, TableCell, CircularProgress } from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { getAllMembers } from 'src/server/api/member';
import { getAllMemberIncentives } from 'src/server/api/incentive';

import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import MemberIncentiveToolbar from 'src/sections/incentive/MemberIncentiveToolbar';
import MemberIncentiveTableRow from 'src/sections/incentive/MemberIncentiveTableRow';

// ----------------------------------------------------------------------

export default function MemberIncentivePage({ disable }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const router = useRouter();
  const [date, setDate] = useState();

  const table = useTable({ defaultOrderBy: 'createdAt' });
  const { loading, memberIncentivesCount, incentiveLevelList, memberIncentives, membersList } =
    useSelector((state) => ({
      loading: state.transaction.loading,
      incentiveLevelList: state.incentive.incentiveLevels?.incentiveLevels,
      memberIncentives: state.incentive?.memberIncentives?.memberIncentives,
      memberIncentivesCount: state.incentive?.memberIncentives?.total,
      membersList: state.member?.members?.members,
    }));

  const identifier = 'club-income-transaction';
  const storedRowsPerPage = sessionStorage.getItem(`rowsPerPage_${identifier}`);

  const [selectedIncentiveLevel, setSelectedIncentiveLevel] = useState('');

  const [selectedMember, setSelectedMember] = useState('');
  const [searchMember, setSearchMember] = useState('');

  const handleTextFieldChangeMember = (event) => {
    setSearchMember(event.target.value);
    setPage();
  };

  const handleAutocompleteChangeMember = (e, newValue) => {
    setSelectedMember(newValue || '');
    setPage();
  };

  const handleAutocompleteChangeClub = (e, newValue) => {
    setSelectedIncentiveLevel(newValue || '');
    setPage();
  };

  const [tableData, setTableData] = useState([]);
  const totalPage = memberIncentivesCount;

  useEffect(() => {
    const data = {
      page: '',
      search: searchMember,
      dispatch,
      limit: '',
    };
    dispatch(getAllMembers(data));
  }, [dispatch, searchMember]);

  const TABLE_HEAD = [
    { id: 'date', label: 'Date' },
    { id: 'member', label: 'Member' },
    { id: 'incentive_level', label: 'Incentive Level' },
    { id: 'amount', label: 'Amount' },
    { id: 'expiry_date', label: 'Expiry Date' },
    { id: 'total_given', label: 'Total Given' },
  ];

  const optionClub = [
    { label: 'All', value: '' },
    ...(incentiveLevelList || []).map((results) => ({
      label: results?.rank_name,
      value: results?._id,
    })),
  ];

  const optionMembers = [
    { label: 'All', value: null },
    ...(membersList || []).map((item) => ({
      name: item?.name,
      member_id: item?.member_id,
      username: item?.username,
      label: `${item?.name} - ${item?.member_id} - ${item?.username ?? ''}`,
      value: item?._id,
    })),
  ];

  useEffect(() => {
    const data = {
      dispatch,
      page: table.page + 1,
      limit: storedRowsPerPage || table.rowsPerPage,
      selectedMember: selectedMember?.value,
      selectedIncentiveLevel: selectedIncentiveLevel?.value,
      date,
    };
    dispatch(getAllMemberIncentives(data));
  }, [
    dispatch,
    date,
    table.page,
    table.rowsPerPage,
    storedRowsPerPage,
    selectedIncentiveLevel?.value,
    selectedIncentiveLevel,
    selectedMember?.value,
  ]);

  useEffect(() => {
    setTableData(memberIncentives);
  }, [memberIncentives]);

  const dataInPage = [];

  const canReset = false;

  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData?.filter((row) => row.id !== id);
      enqueueSnackbar('Delete success!');
      setTableData(deleteRow);
      table.onUpdatePageDeleteRow(dataInPage?.length);
    },
    [dataInPage?.length, enqueueSnackbar, table, tableData]
  );

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.edit(id));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.details(id));
    },
    [router]
  );
  const setPage = () => {
    table.setPage(0);
  };

  return (
    <Stack>
      <Card>
        <Stack mx={2} my={3}>
          <MemberIncentiveToolbar
            date={date}
            setDate={setDate}
            // handleTextFieldChangeClub={handleTextFieldChangeClub}
            handleAutocompleteChangeClub={handleAutocompleteChangeClub}
            optionClub={optionClub}
            handleTextFieldChangeMember={handleTextFieldChangeMember}
            handleAutocompleteChangeMember={handleAutocompleteChangeMember}
            optionMembers={optionMembers}
            setPage={setPage}
          />
        </Stack>
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            dense={table.dense}
            numSelected={table.selected?.length}
            rowCount={tableData?.length}
            onSelectAllRows={(checked) => {
              table.onSelectAllRows(
                checked,
                tableData.map((row) => row.id)
              );
            }}
          />
          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length}
                numSelected={table.selected?.length}
                onSort={table.onSort}
              />

              {loading && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <CircularProgress color="inherit" />
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

              <TableBody>
                {(tableData || []).map((row, index) => (
                  <MemberIncentiveTableRow
                    // sort={sort}
                    index={index}
                    page={table.page + 1}
                    rowsPerPage={storedRowsPerPage || table.rowsPerPage || 25}
                    key={row.id}
                    row={row}
                    selected={table?.selected?.includes(row.id)}
                    onSelectRow={() => table.onSelectRow(row.id)}
                    onViewRow={() => handleViewRow(row.id)}
                    onEditRow={() => handleEditRow(row.id)}
                    onDeleteRow={() => handleDeleteRow(row.id)}
                    loading={loading}
                    colSpan={TABLE_HEAD?.length}
                  />
                ))}
              </TableBody>
              <TableNoData notFound={notFound} />
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          customRowsPerPage={25}
          count={totalPage || 0}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          dense={table.dense}
          onChangeDense={table.onChangeDense}
          identifier={identifier}
        />
      </Card>
    </Stack>
  );
}

MemberIncentivePage.propTypes = {
  disable: PropTypes.string,
};
