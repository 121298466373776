import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import parsePhoneNumberFromString, { getCountryCallingCode } from 'libphonenumber-js';

import { LoadingButton } from '@mui/lab';
import DoneIcon from '@mui/icons-material/Done';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Card, Stack, Container, Typography, IconButton, InputAdornment } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { referralIdCheckApi } from 'src/server/api/member';
import { CheckUserName, memberRegisterProfileApi } from 'src/server/api/auth';

import Iconify from 'src/components/iconify';
import { RHFTextField } from 'src/components/hook-form';
import { useSettingsContext } from 'src/components/settings';
import FormProvider from 'src/components/hook-form/form-provider';
import { RHFPhoneInput } from 'src/components/hook-form/rhf-phone-input';

export default function RegisterView() {
  const isMobile = useResponsive('down', 'md');
  // const params = useParams();
  // const ReferralId = location?.search?.split('?ref_id=')[1];
  const ReferralId = sessionStorage.getItem('refId');
  const MemberEmail = sessionStorage.getItem('memberEmail');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const password = useBoolean();
  const [selectedCode, setSelectedCode] = useState('91');
  const settings = useSettingsContext();
  const [referralIdCheck, setReferralIdCheck] = useState('');
  useEffect(() => {
    setReferralIdCheck(ReferralId);
  }, [ReferralId, dispatch]);
  const [userNameCheck, setUserNameCheck] = useState('');

  const { userNamesCheck, loading, verifyRegisterOTP, ReferralIdState } = useSelector((state) => ({
    userNamesCheck: state.auth.CheckUserNames,
    verifyRegisterOTP: state.auth.verifyRegisterOTP,
    ReferralIdState: state.member.ReferralId,

    loading: state.auth.loginLoading,
  }));
  console.log(verifyRegisterOTP);
  const registerSchema = Yup.object().shape({
    name: Yup.string().required('Name required'),
    phone: Yup.string().required('Phone required'),
    // .min(10, 'Phone must be at least 10 characters')
    // .max(10, 'Phone must not exceed 10 characters'),
    referral_id: Yup.string(),
    // company_name: Yup.string(),
    username: Yup.string()
      .min(6, 'Username must be at least 5 characters')
      .matches(/^[a-zA-Z0-9]*$/, 'Username can only contain letters and numbers')
      .matches(/^\S*$/, 'Username cannot contain spaces'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
  });

  const defaultValues = {
    referral_id: ReferralId || '',
    // email: account?.email || '',
    // phone: account?.phone || '',
  };
  // console.log(selectedCountryCode);
  const methods = useForm({
    resolver: yupResolver(registerSchema),
    defaultValues,
  });

  const {
    // setValue,
    // reset,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors },
  } = methods;
  console.log(errors);
  const passwordValue = watch('password');
  const confirmPasswordValue = watch('ConfirmPassword');

  const onSubmit = handleSubmit(async (data) => {
    const credentials = {
      state: {
        // ...data,
        name: data?.name,
        phone: parsePhoneNumberFromString(data?.phone)?.nationalNumber,

        country_code: selectedCode || '',
        referral_id: referralIdCheck,
        // company_name: data?.company_name,
        username: userNameCheck,
        password: data?.password,
      },
      dispatch,
      navigate,
    };
    // console.log(credentials)
    dispatch(memberRegisterProfileApi(credentials));
  });
  const handleReferralIdChange = (event) => {
    const { value } = event.target;
    // console.log('User Name Value:', value);
    setReferralIdCheck(value);
  };
  useEffect(() => {
    if (referralIdCheck && referralIdCheck?.length >= 6) {
      const credential = {
        state: {
          referral_id: referralIdCheck.toUpperCase(),
        },
      };
      dispatch(referralIdCheckApi(credential));
    }
  }, [dispatch, referralIdCheck]);
  // const handleTextFieldChangeCourse = (event) => {
  //   const searchText = event.target.value.trim().toLowerCase();
  //   const filteredOptions = countryCodes
  //     .filter((country) => country.name && country.name.trim().toLowerCase().includes(searchText))
  //     .map((result) => ({
  //       label: result.name,
  //       value: result.code,
  //     }));
  //   console.log(filteredOptions);
  // };
  const handleUserNameChange = (event) => {
    const { value } = event.target;
    // console.log('User Name Value:', value);
    const trimmedValue = value.replace(/\s/g, ''); // Remove spaces
    setUserNameCheck(trimmedValue);

    // setUserNameCheck(value);
  };

  useEffect(() => {
    if (userNameCheck) {
      const credential = {
        state: {
          username: userNameCheck,
        },
      };
      dispatch(CheckUserName(credential));
    }
  }, [dispatch, userNameCheck]);

  const handlePhoneInputChange = (value, inputName) => {
    const country_code = getCountryCallingCode(value);
    setSelectedCode(country_code);
  };
  const renderForm = (
    <>
      <Box
        sx={{
          border: '1px dashed',
          borderColor: 'grey.400',
          borderRadius: 1,
          padding: 1.5,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Box>
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{
              position: 'absolute',
              backgroundColor: settings?.themeMode === 'dark' ? '#443333' : 'white',
              top: -10,
              left: 12,
            }}
          >
            Email
          </Typography>
          <Typography color="#637381" variant="caption">
            {MemberEmail}{' '}
          </Typography>
        </Box>
      </Box>
      <Stack direction="row" gap={1} mt={1} mb={2} alignItems="center">
        <DoneIcon sx={{ color: '#118D57', width: '15px' }} />
        <Typography color="#118D57" variant="caption">
          Email verified
        </Typography>
      </Stack>
      <Stack mb={2.5}>
        <RHFTextField
          name="name"
          label="Name"
          required
          placeholder="Enter full name"
          InputLabelProps
        />
      </Stack>
      <RHFPhoneInput
        name="phone"
        label="Phone"
        placeholder="Enter phone number"
        setSelectedCode={(value) => handlePhoneInputChange(value, 'phone')}
      />
      <Box
        rowGap={2}
        mt={2.5}
        columnGap={1}
        display="grid"
        marginBottom={2}
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        }}
      >
        <Stack>
          <RHFTextField
            name="referral_id"
            label="Referral ID"
            onChange={handleReferralIdChange}
            value={referralIdCheck}
            placeholder="Enter Referral ID"
            InputLabelProps
            inputProps={{ style: { textTransform: 'uppercase' } }}
            required
          />
          {referralIdCheck && (
            <>
              {ReferralIdState === false && referralIdCheck?.length >= 6 && (
                <Typography
                  color="#FF5630"
                  fontSize={12}
                  fontWeight={400}
                  display="flex"
                  alignItems="center"
                  ml={2}
                  mt={0.5}
                >
                  {/* <Iconify icon="solid/ic-eva:alert-triangle-fill" />  */}
                  <WarningIcon
                    sx={{ fontSize: '14px', marginRight: '4px', color: '#FF5630' }}
                  />{' '}
                  Invalid Referral ID{' '}
                </Typography>
              )}
              {ReferralIdState === true && referralIdCheck?.length >= 6 && (
                <Typography
                  ml={2}
                  mt={0.5}
                  sx={{
                    color: 'success.main',
                    fontSize: '10px',
                    fontWeight: '600',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Verified <CheckCircleIcon sx={{ fontSize: '14px', marginLeft: '4px' }} />
                </Typography>
              )}
            </>
          )}
        </Stack>
        <Stack>
          <RHFTextField
            name="username"
            label="Username"
            placeholder="Enter username"
            InputLabelProps
            required
            // inputProps={{ minLength: 5 }}
            //     onInput={(e) => {
            //       const input = e.target.value.slice(0, 5);
            //       e.target.value = input;
            //     }}
            onChange={handleUserNameChange}
            value={userNameCheck}
          />
          {userNameCheck && (
            <>
              {userNamesCheck === false && (
                <Typography
                  color="#FF5630"
                  fontSize={12}
                  fontWeight={400}
                  display="flex"
                  alignItems="center"
                  ml={2}
                  mt={0.5}
                >
                  {/* <Iconify icon="solid/ic-eva:alert-triangle-fill" />  */}
                  <WarningIcon
                    sx={{ fontSize: '14px', marginRight: '4px', color: '#FF5630' }}
                  />{' '}
                  Username not available{' '}
                </Typography>
              )}
              {userNamesCheck === true && (
                <Typography
                  ml={2}
                  mt={0.5}
                  sx={{
                    color: 'success.main',
                    fontSize: '10px',
                    fontWeight: '600',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Verified <CheckCircleIcon sx={{ fontSize: '14px', marginLeft: '4px' }} />
                </Typography>
              )}
            </>
          )}
        </Stack>
      </Box>
      <Box
        rowGap={2.5}
        mt={2.5}
        mb={2}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)',
        }}
      >
        <RHFTextField
          name="password"
          label="Password"
          placeholder="Enter Password"
          InputLabelProps
          type={password.value ? 'text' : 'password'}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="ConfirmPassword"
          type={password.value ? 'text' : 'password'}
          label="Confirm"
          placeholder="Enter Confirm Password"
          InputLabelProps={{ shrink: true }}
          // size='small'
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          // onChange={(e) => {
          //   const confirmPasswordValue = e.target.value;
          //   const passwordValue = methods.getValues('password');
          //   setPasswordMatch(passwordValue === confirmPasswordValue);
          // }}
        />
      </Box>
      <Box>
        {confirmPasswordValue && passwordValue !== confirmPasswordValue && (
          <Stack textAlign="right">
            <Typography
              variant="body2"
              color="error"
              display="flex"
              alignItems="center"
              justifyContent="end"
              mr={2}
            >
              <WarningIcon sx={{ fontSize: '14px', marginRight: '4px', color: '#FF5630' }} />{' '}
              Password does not match
            </Typography>
          </Stack>
        )}
      </Box>

      <Stack spacing={3} mt={5} mb={1}>
        <LoadingButton
          type="submit"
          variant="contained"
          color="success"
          size="large"
          sx={{ backgroundColor: '#007867', '&:hover': { backgroundColor: '#044f45' } }}
          loading={isSubmitting || loading}
        >
          Register{' '}
        </LoadingButton>
      </Stack>
    </>
  );
  return (
    <Container maxWidth="lg">
      <MetaHelmet title="Winbuks" miniDescription="Member Registration" />

      <Stack maxWidth={420} marginX="auto">
        <Card sx={{ p: 2, mt: 2 }}>
          <Stack textAlign="center" gap={0.5} mb={2} mt={1}>
            <Typography variant="h4" fontWeight={700}>
              Sign up to join Winbuks{' '}
            </Typography>
            <Typography
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              display="flex"
              fontWeight={400}
              color="#637381"
              variant="caption"
            >
              Complete your details below to register.{' '}
            </Typography>
          </Stack>
          <Stack p={isMobile ? 0 : 1} pb={1}>
            <FormProvider methods={methods} onSubmit={onSubmit}>
              {renderForm}
            </FormProvider>
            {isMobile ? (
              <Stack alignItems={isMobile ? '' : 'center'} flexDirection="column">
                <Typography
                  fontSize={12}
                  fontWeight={400}
                  color="#637381"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  By registering, I agree to
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography
                    component={Link}
                    to={paths?.public?.terms_conditions}
                    px={1}
                    fontSize={12}
                    fontWeight={400}
                    sx={{ textDecoration: 'underline', color: '#637381', cursor: 'pointer' }}
                  >
                    {' '}
                    Terms & Conditions{' '}
                  </Typography>{' '}
                  <Typography fontWeight={400} fontSize={12}>
                    {' '}
                    and{' '}
                  </Typography>
                  <Typography
                    component={Link}
                    to={paths?.public?.privacy_policy}
                    px={1}
                    fontSize={12}
                    fontWeight={400}
                    sx={{ textDecoration: 'underline', color: '#637381', cursor: 'pointer' }}
                  >
                    {' '}
                    Privacy Policy
                  </Typography>
                </Box>
              </Stack>
            ) : (
              <Stack spacing={3} alignItems={isMobile ? '' : 'center'}>
                <Typography fontSize={12} fontWeight={400} color="#637381" display="flex">
                  By registering, I agree to
                  <Typography
                    component={Link}
                    to={paths?.public?.terms_conditions}
                    mx="5px"
                    fontSize={12}
                    fontWeight={400}
                    sx={{ textDecoration: 'underline', color: '#637381', cursor: 'pointer' }}
                  >
                    {' '}
                    Terms & Conditions{' '}
                  </Typography>{' '}
                  <Typography fontWeight={400} fontSize={12}>
                    {' '}
                    and{' '}
                  </Typography>
                  <Typography
                    component={Link}
                    to={paths?.public?.privacy_policy}
                    mx="5px"
                    fontSize={12}
                    fontWeight={400}
                    sx={{ textDecoration: 'underline', color: '#637381', cursor: 'pointer' }}
                  >
                    {' '}
                    Privacy Policy
                  </Typography>
                </Typography>
              </Stack>
            )}
          </Stack>
        </Card>
      </Stack>
    </Container>
  );
}
