/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { Box, Paper, TableRow, TableCell, Typography, CircularProgress } from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import { getAllEnquiry } from 'src/server/api/referrals';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import EnquirySort from 'src/sections/Enquiry/EnquirySort';
import CreateEnquiry from 'src/sections/Enquiry/createEnqry';
import EnquiryToolbar from 'src/sections/Enquiry/EnquiryToolbar';
import EnquiryTableRow from 'src/sections/Enquiry/EnquiryTableRow';

// ----------------------------------------------------------------------

export default function AllEnquiry({ disable }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const router = useRouter();
  const table = useTable({ defaultOrderBy: 'createdAt', defaultRowsPerPage: 25 });
  const confirm = useBoolean();
  const navigate = useNavigate();
  const role = useGetRoles()?.role;
  const isMobile = useResponsive('down', 'md');

  const { loading, allEnquiry, total } = useSelector((state) => ({
    loading: state.referrals.loading,
    allEnquiry: state?.referrals?.allEnquiry?.enquiries,
    total: state?.referrals?.allEnquiry,
    membersList: state.member.members?.members,
  }));
  // console.log(allEnquiry);
  const identifier = 'enquiry';
  const storedRowsPerPage = sessionStorage.getItem(`rowsPerPage_${identifier}`);

  const [searchText, setSearchText] = useState();
  const [sort, setSort] = useState('');
  const [date, setDate] = useState('');
  // console.log(setSort);
  const [tableData, setTableData] = useState([]);
  const totalPage = total?.total;
  const [openEnqryDialog, setOpenEnqryDialog] = useState(false);
  // console.log(total);

  const TABLE_HEAD = [
    { id: 'date', label: 'Date' },
    { id: 'first_name', label: 'Name' },
    { id: 'email', label: 'Email' },
    // { id: 'message', label: 'Message' },
    // { id: 'subject', label: 'Subject' },
    { id: 'status', label: 'Status' },
  ];

  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      limit: disable ? 5 : storedRowsPerPage || table.rowsPerPage,
      date,
      dispatch,
      sort,
      search: searchText,
    };
    dispatch(getAllEnquiry(credentials));
  }, [
    date,
    disable,
    dispatch,
    role,
    searchText,
    sort,
    storedRowsPerPage,
    table.page,
    table.rowsPerPage,
  ]);

  useEffect(() => {
    setTableData(allEnquiry);
  }, [allEnquiry]);

  const dataInPage = [];

  const canReset = false;

  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData?.filter((row) => row.id !== id);
      enqueueSnackbar('Delete success!');
      setTableData(deleteRow);
      table.onUpdatePageDeleteRow(dataInPage?.length);
    },
    [dataInPage?.length, enqueueSnackbar, table, tableData]
  );

  const handleDeleteRows = () => {};

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.edit(id));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.details(id));
    },
    [router]
  );

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const HandlePage = () => {
    navigate(paths.dashboard.transaction, {});
  };
  const setPage = () => {
    table.setPage(0);
  };
  return (
    <>
      {!disable && (
        <CustomBreadcrumbs
          // heading="Transaction"
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },
            {
              name: 'Enquiries',
            },
            {
              name: 'List',
            },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
      )}

      {openEnqryDialog && (
        <CreateEnquiry
          setOpen={setOpenEnqryDialog}
          open={openEnqryDialog}
          // setIsUpdate={setInvestorData}
        />
      )}

      <Stack
        width={isMobile ? '100%' : '80%'}
        // border='2px solid orange'
      >
        <Card>
          <Stack p={2} direction="row" alignContent="center" justifyContent="space-between">
            <Typography variant="h6">All Enquiries</Typography>

            <Button variant="contained" onClick={() => setOpenEnqryDialog(true)}>
              Create Enquiry
            </Button>
          </Stack>
          {/* {!disable && ( */}
          <>
            <EnquirySort setPage={setPage} sort={sort} setSort={setSort} allList={total} />
            <Stack mx={2} my={3}>
              <EnquiryToolbar
                setPage={setPage}
                role={role}
                onSearch={handleSearch}
                date={date}
                setDate={setDate}
              />
            </Stack>
          </>
          {/* )} */}
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) => {
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                );
              }}
            />
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={table.selected?.length}
                  onSort={table.onSort}
                />

                {loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress color="inherit" />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                <TableBody>
                  {(tableData || []).map((row, index) => (
                    <EnquiryTableRow
                      index={index}
                      page={table.page + 1}
                      rowsPerPage={storedRowsPerPage || table.rowsPerPage || 5}
                      key={row.id}
                      row={row}
                      selected={table?.selected?.includes(row.id)}
                      onSelectRow={() => table.onSelectRow(row.id)}
                      onViewRow={() => handleViewRow(row.id)}
                      onEditRow={() => handleEditRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      loading={loading}
                      colSpan={TABLE_HEAD?.length}
                    />
                  ))}
                </TableBody>

                {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                />  */}

                <TableNoData notFound={notFound} />
              </Table>
            </Scrollbar>
          </TableContainer>
          {disable && (
            <Box sx={{ p: 2, textAlign: 'right' }}>
              <Button
                size="small"
                color="inherit"
                onClick={HandlePage}
                endIcon={<Iconify icon="eva:arrow-ios-forward-fill" width={18} sx={{ ml: -0.5 }} />}
              >
                View All
              </Button>
            </Box>
          )}
          {!disable && (
            <TablePaginationCustom
              count={totalPage || 0}
              customRowsPerPage={25}
              page={table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
              dense={table.dense}
              onChangeDense={table.onChangeDense}
              identifier={identifier}
            />
          )}
        </Card>
      </Stack>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

AllEnquiry.propTypes = {
  disable: PropTypes.string,
};
