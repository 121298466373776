import { configureStore } from '@reduxjs/toolkit';

import appReducer from './app';
import faqReducer from './faq';
import authReducer from './auth';
import clubReducer from './club';
import commonReducer from './common';
import memberReducer from './member';
import walletReducer from './wallet';
import CountryReducer from './country';
import settingsReducer from './settings';
import referralsReducer from './referral';
import incentiveReducer from './incentive';
import dashboardReducer from './dashboard';
import memberClubReducer from './memberClub';
import transactionReducer from './transaction';
import walletAddressReducer from './walletAddress';
import withdrawalRequestReducer from './withdrawalRequest';

export default configureStore({
  reducer: {
    common: commonReducer,
    auth: authReducer,
    app: appReducer,
    dashboard: dashboardReducer,
    country: CountryReducer,
    member: memberReducer,
    memberClub: memberClubReducer,
    club: clubReducer,
    settings: settingsReducer,
    referrals: referralsReducer,
    transaction: transactionReducer,
    wallet: walletReducer,
    withdrawalRequest: withdrawalRequestReducer,
    walletAddress: walletAddressReducer,
    incentive: incentiveReducer,
    faq: faqReducer,
  },
});
