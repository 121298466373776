import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { Paper, Alert, TableRow, TableCell, Typography, CircularProgress } from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { getAllWithdrawalRequestAdmin } from 'src/server/api/withdrawalRequest';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import WithdrawalRequestToolbar from 'src/sections/withdrawalRequest/withdrawalRequestToolbar';
import WithdrawalReportTableRow from 'src/sections/withdrawalRequest/withdrawalReportTableRow';

// ----------------------------------------------------------------------

export default function AllWithdrawalReportsPage({ memberSingle }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const router = useRouter();

  const table = useTable({
    defaultOrderBy: 'createdAt',
    defaultRowsPerPage: 25,
  });

  const confirm = useBoolean();
  const isMobile = useResponsive('down', 'md');
  const [date, setDate] = useState('');

  const { loading, dataList } = useSelector((state) => ({
    loading: state.referrals.loading,
    dataList: state.withdrawalRequest.withdrawalRequests,
  }));

  const identifier = 'withdrawal_request';
  const storedRowsPerPage = sessionStorage.getItem(`rowsPerPage_${identifier}`);
  const [searchText, setSearchText] = useState();
  const [tableData, setTableData] = useState([]);
  const totalPage = dataList?.total;

  const TABLE_HEAD = [
    { id: 'request_id', label: 'request ID' },
    { id: 'member', label: 'Member' },
    { id: 'wallet_address', label: 'Wallet Address' },
    { id: 'approved_date', label: 'Approved Date' },
    { id: 'amount', label: 'Amount' },
    { id: 'action', label: 'Action' },
  ];

  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      limit: memberSingle ? 5 : storedRowsPerPage || table.rowsPerPage,
      sort: 'APPROVED',
      date,
      search: searchText || '',
      dispatch,
      member: memberSingle,
    };
    dispatch(getAllWithdrawalRequestAdmin(credentials));
  }, [date, dispatch, searchText, storedRowsPerPage, table.page, table.rowsPerPage, memberSingle]);

  useEffect(() => {
    setTableData(dataList?.withdrawalRequests);
  }, [dataList]);

  const dataInPage = [];

  const canReset = false;

  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData?.filter((row) => row.id !== id);
      enqueueSnackbar('Delete success!');
      setTableData(deleteRow);
      table.onUpdatePageDeleteRow(dataInPage?.length);
    },
    [dataInPage?.length, enqueueSnackbar, table, tableData]
  );

  const handleDeleteRows = () => {};

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.edit(id));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.details(id));
    },
    [router]
  );

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const getWidth = () => {
    if (memberSingle) {
      return '100%';
    }
    if (isMobile) {
      return '100%';
    }
    return '85%';
  };

  const setPage = () => {
    table.setPage(0);
  };

  return (
    <>
      {!memberSingle && (
        <CustomBreadcrumbs
          heading="Withdrawal Reports"
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },
            {
              name: 'withdrawal reports',
            },

            {
              name: 'List',
            },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
      )}

      <Stack spacing={3} direction="column" width={getWidth()}>
        <Card variant="outlined" sx={{ boxShadow: 'unset', flex: 7 }}>
          {!memberSingle && (
            <Stack p={2}>
              <Typography variant="h6">Withdrawal reports ({totalPage})</Typography>
            </Stack>
          )}

          <Stack mx={2} my={3}>
            <WithdrawalRequestToolbar
              setPage={setPage}
              onSearch={handleSearch}
              date={date}
              setDate={setDate}
            />
          </Stack>

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) => {
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                );
              }}
            />
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={table.selected?.length}
                  onSort={table.onSort}
                />

                {loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress color="inherit" />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                <TableBody>
                  {(tableData || []).map((row, index) => (
                    <WithdrawalReportTableRow
                      index={index}
                      page={table.page + 1}
                      rowsPerPage={storedRowsPerPage || table.rowsPerPage || 25}
                      key={row.id}
                      row={row}
                      selected={table?.selected?.includes(row.id)}
                      onSelectRow={() => table.onSelectRow(row.id)}
                      onViewRow={() => handleViewRow(row.id)}
                      onEditRow={() => handleEditRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      loading={loading}
                      colSpan={TABLE_HEAD?.length}
                      memberSingle={memberSingle}
                    />
                  ))}
                </TableBody>

                <TableNoData notFound={notFound} />
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            customRowsPerPage={25}
            count={totalPage || 0}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
            identifier={identifier}
          />
        </Card>
      </Stack>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

AllWithdrawalReportsPage.propTypes = {
  memberSingle: PropTypes.string,
};

const WithdrawalNotification = ({ color, message }) => (
  <Stack mx={2} mb={2}>
    <Alert
      className="alert-custom"
      icon={<Iconify icon="material-symbols:info" sx={{ color: color || '#7A4100' }} />}
      sx={{
        bgcolor: '#F8F6F2',
        color: '#7A4100',
        borderRadius: '10px',
        display: 'inline-flex',
        alignItems: 'center',
        p: 0.5,
        px: 1,
        mb: 1,
        width: '100%',
      }}
    >
      <Typography variant="caption" color="#7A4100" sx={{ fontWeight: 500, lineHeight: '1px' }}>
        {message}{' '}
      </Typography>
    </Alert>
  </Stack>
);

WithdrawalNotification.propTypes = {
  color: PropTypes.string,
  message: PropTypes.string,
};
