import PropTypes from 'prop-types';

import { Box, Grid, Stack, TextField, Autocomplete } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import BasicDateRangePicker from 'src/pages/Common/Static/calender';

// ----------------------------------------------------------------------

export default function ClubIncomeTransactionToolbar({
  handleTextFieldChangeClub,
  handleAutocompleteChangeClub,
  optionClub,
  date,
  setDate,
  handleTextFieldChangeMember,
  handleAutocompleteChangeMember,
  optionMembers,
  setPage,
}) {
  const role = useGetRoles()?.role;
  const isMobile = useResponsive('down', 'md');

  // console.log(optionMembers);
  return (
    <>
      {role === 'admin' ? (
        <Stack direction="row" alignItems="center" spacing={2} pr={2}>
          <Grid container>
            <Grid md={4} mt={1} xs={6}>
              <Autocomplete
                id="country-select-demo"
                sx={{ width: '100%' }}
                options={optionClub}
                autoHighlight
                size="small"
                // className="small-select"
                getOptionLabel={(option) => option.label}
                onChange={handleAutocompleteChangeClub}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.label}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Club"
                    onChange={handleTextFieldChangeClub}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid md={4} mt={1} px={1} xs={6}>
              <Autocomplete
                id="country-select-demo"
                sx={{ width: '100%' }}
                options={optionMembers}
                autoHighlight
                size="small"
                // className="small-select"
                getOptionLabel={(option) => option.label}
                onChange={handleAutocompleteChangeMember}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.label}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Member"
                    onChange={handleTextFieldChangeMember}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid md={4} className="club_transaction" width="" xs={6}>
              <BasicDateRangePicker
                setPage={setPage}
                selectedRange={date}
                setSelectedRange={setDate}
              />
            </Grid>
          </Grid>
          {/* <TextField
        onChange={(e) => onSearch(e.target.value)}
        placeholder="Search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ ml: 1, color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      /> */}
        </Stack>
      ) : (
        <Stack direction="row" width="50%">
          <TextField
            name="date"
            label="Start Date"
            sx={{ width: '100%', minWidth: isMobile && 150, mr: 2 }}
            size="medium"
            type="date"
            variant="outlined"
            inputProps={{ maxLength: 4 }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setDate({
                ...date,
                from: e.target.value,
              });
              setPage();
            }}
          />
          <TextField
            name="date"
            label="End Date"
            sx={{ width: '100%', minWidth: isMobile && 150 }}
            size="medium"
            type="date"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setDate({
                ...date,
                to: e.target.value,
              });
              setPage();
            }}
          />
        </Stack>
      )}
    </>
  );
}

ClubIncomeTransactionToolbar.propTypes = {
  date: PropTypes.string,
  setDate: PropTypes.func,
  optionClub: PropTypes.array,
  handleTextFieldChangeClub: PropTypes.func,
  handleAutocompleteChangeClub: PropTypes.func,
  optionMembers: PropTypes.array,
  handleTextFieldChangeMember: PropTypes.func,
  handleAutocompleteChangeMember: PropTypes.func,
  setPage: PropTypes.any,
};
