import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';

import { Tab, Tabs, alpha } from '@mui/material';

import { getAllClubs } from 'src/server/api/club';

import Label from 'src/components/label';

// ----------------------------------------------------------------------

export default function ClubMemberSort({ sort, setSort, memberCount, setPage }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { clubList } = useSelector((state) => ({
    clubList: state.club.clubs?.clubs,
  }));
  useEffect(() => {
    dispatch(getAllClubs(dispatch));
  }, [dispatch]);
  // console.log(clubList);

  const TABS = [
    {
      value: '',
      label: 'All',
      color: 'default',
      count: memberCount || 0,
    },
    ...(clubList?.map((club) => ({
      value: club?._id,
      label: club?.title,
      color: 'default',
      count: club?.activeMemberClubCount || 0,
    })) || []),
  ];

  return (
    <Tabs
      className="custom-tab"
      value={sort}
      onChange={(e, newValue) => {
        setSort(newValue);
        setPage();
      }}
      sx={{
        px: 2.5,
        // pl:0,
        width: '100%',
        // px:.1,
        boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
      }}
    >
      {TABS?.map((tab) => (
        <Tab
          key={tab.value}
          value={tab.value}
          label={tab.label}
          iconPosition="end"
          icon={
            <Label
              variant={((tab.value === 'all' || tab.value === sort) && 'filled') || 'soft'}
              color={tab.color}
            >
              {tab.count}
            </Label>
          }
        />
      ))}
    </Tabs>
  );
}

ClubMemberSort.propTypes = {
  setSort: PropTypes.func,
  sort: PropTypes.string,
  memberCount: PropTypes.any,
  setPage: PropTypes.any,
};
