// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  website: '/website',
};

// ----------------------------------------------------------------------

export const paths = {
  public: {
    landing: '/home',
    academy_landing: ROOTS.LMS,
    course_view: (id) => `${ROOTS.LMS}/course/${id}`,
    plan: `/plan`,
    // about: `/about`,
    contact: `/contact`,
    terms_conditions: `/terms`,
    privacy_policy: `/privacy`,
    dashboard_loading: (id) => `/dashboard_loading/${id}`,
  },
  // AUTH
  auth: {
    login: `/login/admin`,
    login_member: `/login`,
    login_otp_member: `/login/otp`,
    register: `/register`,

    sign_up: `/sign-up`,
    sign_up_otp: `/sign-up-otp`,

    forgot_password: `/forgot/password`,

    logout: `${ROOTS.AUTH}/logout`,

    create_password: (type, token) => `/create/password/:${type}/:${token}`,
    forgot_password_email: (userId, token) => `/password/reset/:${userId}/:${token}`,
    password_verification: (type, token) => `/invitation/:${type}/:${token}`,
  },
  website: {},
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    onBoarding: '/on-boarding',

    profile: `${ROOTS.DASHBOARD}/profile`,
    enquiry: `${ROOTS.DASHBOARD}/enquiry`,
    referral: `${ROOTS.DASHBOARD}/referral`,
    transaction: `${ROOTS.DASHBOARD}/transactions`,
    income: `${ROOTS.DASHBOARD}/income`,
    learning: `${ROOTS.DASHBOARD}/learning`,

    club: `${ROOTS.DASHBOARD}/clubs`,
    incentive: `${ROOTS.DASHBOARD}/incentive`,
    bionium: `${ROOTS.DASHBOARD}/bionium`,
    withdrawal_requests: `${ROOTS.DASHBOARD}/withdrawal/requests`,
    withdrawal_report: `${ROOTS.DASHBOARD}/withdrawal/reports`,

    member: `${ROOTS.DASHBOARD}/member`,
    club_members: `${ROOTS.DASHBOARD}/club/members`,

    member_view: (id) => `${ROOTS.DASHBOARD}/member/${id}`,
    member_create: `${ROOTS.DASHBOARD}/member/create`,

    settings: `${ROOTS.DASHBOARD}/settings`,
    chain: `${ROOTS.DASHBOARD}/chain-node`,
  },
};

export const AuthRoles = {
  admin: 'Admin',
  member: 'Member',
};
