import React from 'react';

import { Container } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { useSettingsContext } from 'src/components/settings';

import AllWithdrawalReportsPage from './WithdrawalReportPage';

function AllWithdrawalReports() {
  const settings = useSettingsContext();

  return (
    <>
      <MetaHelmet title="Winbuks" miniDescription="Withdrawal Reports" />
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <AllWithdrawalReportsPage />
      </Container>
    </>
  );
}

export default AllWithdrawalReports;
