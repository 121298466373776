import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Link, Tooltip, Typography, ListItemText } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { USDT } from 'src/hooks/use-USDT';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import { fDateTimeSimple } from 'src/utils/format-time';

import Label from 'src/components/label/label';
import TextMaxLine from 'src/components/text-max-line/text-max-line';

// ----------------------------------------------------------------------

export default function ClubIncomeTransactionTableRow({ row, selected, sort }) {
  // console.log(sort);
  const { role } = useGetRoles();

  const { createdAt, amount, description, club, category, member, transaction_type } = row;
  // const getColorByTransactionType = () => {
  //   switch (transaction_type) {
  //     case 'CREDIT':
  //       return 'success';
  //     case 'DEBIT':
  //       return 'error';
  //     default:
  //       return 'default';
  //   }
  // };
  // const settings = useSettingsContext();
  // let backgroundColor;
  // if (settings?.themeMode === 'dark') {
  //   backgroundColor = 'unset';
  // } else {
  //   backgroundColor = transaction_type === 'CREDIT' ? '#ebf8f3' : '#fff1ee';
  // }
  // console.log(club);
  return (
    <TableRow
      hover
      selected={selected}
      // sx={{
      //   backgroundColor,
      // }}
      // sx={{ backgroundColor: transaction_type === 'CREDIT' ? '#ebf8f3' : '#fff1ee' }}
    >
      <TableCell>
        {/* <ListItemText
          primary={fDate(createdAt)}
          secondary={fTime(createdAt)}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        /> */}
        <ListItemText
          primary={fDateTimeSimple(createdAt)}
          // secondary={fTime(createdAt)}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>

      <TableCell>
        <TextMaxLine line={2}>
          <Typography variant="body2">{description}</Typography>
        </TextMaxLine>
      </TableCell>
      {role === 'admin' && (
        <>
          {sort && sort === 'MEMBER_CLUB' && (
            <TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                {' '}
                <Link href={`${paths?.dashboard?.member_view(member?._id)}`} color="#7A4100">
                  <Tooltip title="View Member Details">{member?.member_id}</Tooltip>
                </Link>
              </TableCell>
            </TableCell>
          )}
          {sort !== 'MEMBER_CLUB' && sort !== 'CLUB' && (
            <TableCell>
              <Typography variant="body2">
                {category === 'CLUB' ? 'Company Club' : 'Member Club'}
              </Typography>
            </TableCell>
          )}
        </>
      )}
      {/* <TableCell>
        <Typography variant="body2" fontWeight={600}>{`${amount?.toFixed(3)} ${USDT}`}</Typography>
      </TableCell> */}
      {/* <TableCell>{wallet}</TableCell> */}
      <TableCell>
        {club ? (
          // <Label variant="soft" color="info" sx={{ fontSize: '11px' }}>
          //   {club?.title}
          // </Label>
          <Label
            variant="soft"
            color={
              (club?.title === 'BENE' && 'success') ||
              (club?.title === 'UJIN' && 'info') ||
              (club?.title === 'CIAN' && 'secondary') ||
              (club?.title === 'KIAH' && 'warning') ||
              (club?.title === 'SAYAN' && 'error') ||
              'default'
            }
          >
            {club?.title}
          </Label>
        ) : (
          '--'
        )}
      </TableCell>
      {/* <TableCell>{transaction_type}</TableCell> */}
      {/* <TableCell>
        <Label variant="soft" color={getColorByTransactionType()}>
          {transaction_type}
        </Label>
      </TableCell> */}
      <TableCell>
        <Typography variant="body2" color="#007867">
          {transaction_type === 'CREDIT' && `${amount?.toFixed(2)} ${USDT}`}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body2" color="error">
          {transaction_type === 'DEBIT' && `${amount?.toFixed(2)} ${USDT}`}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

ClubIncomeTransactionTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  sort: PropTypes.string,
};
