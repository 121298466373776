import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Link, Tooltip, Typography, ListItemText } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { USDT } from 'src/hooks/use-USDT';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import { fDate, fTime, fDateSimple } from 'src/utils/format-time';

import Label from 'src/components/label/label';
import TextMaxLine from 'src/components/text-max-line/text-max-line';

// ----------------------------------------------------------------------

export default function MemberIncentiveTableRow({ row, selected }) {
  // console.log(sort);
  const { role } = useGetRoles();

  const {
    createdAt,
    amount,
    incentive_level,
    expiry_date,
    is_stoped,
    incentive_allocation_limit,
    total_given_amount,
    total_given_count,
    member,
  } = row;

  return (
    <TableRow hover selected={selected}>
      <TableCell>
        <ListItemText
          primary={fDate(createdAt)}
          secondary={fTime(createdAt)}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>

      {role === 'admin' && (
        <TableCell>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>
            <Link href={`${paths?.dashboard?.member_view(member?._id)}`} color="#7A4100">
              <Tooltip title="View Member Details">
                <Typography variant="body2">{member?.member_id}</Typography>
              </Tooltip>
            </Link>

            <Typography variant="caption">{member?.username}</Typography>
          </TableCell>
        </TableCell>
      )}
      <TableCell>
        <TextMaxLine line={2}>
          <Label
            variant="soft"
            color={
              (incentive_level?.rank_name === 'VICTOR' && 'info') ||
              (incentive_level?.rank_name === 'VIBRON' && 'secondary') ||
              (incentive_level?.rank_name === 'VIGOUR' && 'warning') ||
              (incentive_level?.rank_name === 'VIVIAN' && 'error') ||
              'default'
            }
          >
            {incentive_level?.rank_name}
          </Label>
        </TextMaxLine>
      </TableCell>

      <TableCell>
        <Typography variant="body2" fontWeight={600}>{`${amount} ${USDT} `}</Typography>
        <Typography variant="caption">{`${incentive_level?.is_single_time ? 'Single Shot' : ` x ${incentive_allocation_limit} Months`}`}</Typography>
      </TableCell>

      <TableCell>
        <ListItemText
          primary={fDateSimple(expiry_date)}
          secondary={is_stoped ? 'Stoped' : 'Active'}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
            color: `${is_stoped ? '#DC4C64' : '#14A44D'}`,
          }}
        />
      </TableCell>

      <TableCell>
        <Typography variant="body2" fontWeight={600}>{`${total_given_amount} ${USDT} `}</Typography>
        <Typography variant="caption">{`${total_given_count}/${incentive_allocation_limit} `}</Typography>
      </TableCell>
    </TableRow>
  );
}

MemberIncentiveTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
};
