/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import { Box, Card, Typography } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

// ----------------------------------------------------------------------

export default function IncentiveCard({ incentiveLevelList }) {
  const isMobile = useResponsive('down', 'md');

  return (
    <Stack
      spacing={4}
      // sx={{ p: 2 }}
      flexDirection={isMobile ? 'column' : 'row'}
      justifyContent="space-between"
    >
      {(incentiveLevelList || []).map((contact, index) => (
        <Card>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            // flexDirection="column"
            mx={isMobile && 3}
            flexDirection={isMobile ? 'row' : 'column'}
            sx={{
              position: 'relative',
            }}
          >
            <>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                key={contact.id}
                height="120px"
                width={isMobile ? 'auto' : '400px'}
              >
                <Box>
                  <Typography
                    variant="h7"
                    fontWeight="bold"
                    color={
                      (contact?.rank_name === 'VICTOR' && '#54B4D3') ||
                      (contact?.rank_name === 'VIBRON' && '#3B71CA') ||
                      (contact?.rank_name === 'VIGOUR' && '#E4A11B') ||
                      (contact?.rank_name === 'VIVIAN' && '#DC4C64') ||
                      // (contact?.rank_name === 'SAYAN' && 'error') ||
                      'default'
                    }
                  >
                    {contact?.rank_name} <br />
                  </Typography>
                  <Typography variant="caption">
                    {`Referrals: ${contact?.total_refferals_count ?? 0}`}
                    <br /> {`Team: ${contact?.total_team_count ?? 0}`}
                    <br /> {`Period: ${contact?.membership_age_limit ?? 0} days`}
                  </Typography>
                </Box>
              </Box>
              <Box height="60px">
                <Typography variant="caption">Incentive Amount:</Typography>
                <Typography variant="subtitle2">
                  {`${contact?.monthly_incentive_amount || 0} USDT`}{' '}
                  {contact?.is_single_time
                    ? 'Single Shot'
                    : ` x ${contact?.incentive_allocation_limit} months`}
                </Typography>
              </Box>
            </>
          </Box>
        </Card>
      ))}
    </Stack>
  );
}

IncentiveCard.propTypes = {
  incentiveLevelList: PropTypes.array,
};
