/* eslint-disable import/no-unresolved */
import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { useSetRole, useSetUser, useSetToken, useSetMemberId } from 'src/hooks/useHandleSessions';

import { get, put, post } from './http';
import { getAllEnquiry } from './referrals';
import { getMemberDetails } from './member';
import { activeSnack } from '../store/common';

export const forgotPassword = createAsyncThunk(
  'auth/forgot',
  async ({ state, enqueueSnackbar, handleRoles }) => {
    try {
      const response = await post(`/forgot`, state);
      if (response) {
        enqueueSnackbar('Email Sent Successfully!');
        if (handleRoles) {
          localStorage.setItem('userRole', handleRoles());
        }
        useSetToken(response?.token);
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
export const updatePassword = createAsyncThunk(
  'auth/password/update',
  async ({ state, enqueueSnackbar, token, navigate, userType }) => {
    try {
      const response = await post(`password/reset/${token}`, state);

      if (response) {
        enqueueSnackbar('Password Updated Successfully!');
        useSetToken(response?.token);
        if (userType === 'member') {
          navigate(paths.auth.login_member);
        } else {
          navigate(paths.auth.login);
        }
        // navigate(paths?.auth?.login);

        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const LoginApi = createAsyncThunk('auth/login', async ({ state, dispatch, navigate }) => {
  try {
    const response = await post('dashboard/login', state);

    if (response.is_verified === true) {
      dispatch(activeSnack({ type: 'success', message: 'Successfully Authenticated' }));

      useSetToken(response?.token);
      useSetRole(response?.role);
      useSetMemberId(response?.memberId);

      navigate(paths?.dashboard?.root, { replace: true });
      return response;
    }
    if (response) {
      navigate(paths?.auth?.login_otp_member, { replace: true });
      useSetUser(JSON?.stringify(response));
    }
    dispatch(
      activeSnack({
        type: 'success',
        message: 'An OTP has been successfully sent to your registered email.',
      })
    );
    return response;
  } catch (error) {
    // console.log('error=>', error);
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const logoutApi = createAsyncThunk('auth/logout', async ({ dispatch, navigate, role }) => {
  try {
    const response = await get('/logout');
    if (response) {
      sessionStorage.clear();
      dispatch(activeSnack({ type: 'success', message: 'Sign Off Successfully' }));
      navigate(role !== 'admin' ? paths.auth.login_member : paths.auth.login, { replace: true });
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    sessionStorage.clear();
    navigate(role !== 'admin' ? paths.auth.login_member : paths.auth.login, { replace: true });
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const memberRegisterApi = createAsyncThunk(
  'auth/register',
  async ({ state, dispatch, navigate }) => {
    try {
      const response = await post('/member/register', state);

      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Successfully Registered' }));
        // navigate(paths?.auth?.login_otp_member);
        navigate(`${paths?.auth?.login_otp_member}?register`);

        useSetUser(JSON?.stringify(response));

        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const CheckUserName = createAsyncThunk('check/username', async ({ state }) => {
  try {
    const URL = `/check/username`;
    const response = await post(URL, state);
    if (response) {
      // dispatch(activeSnack({ type: 'success', message: response.message }));
      return response;
    }
    return false;
  } catch (error) {
    throw error?.response?.data?.message;
  }
});

export const verifyOtpApi = createAsyncThunk(
  'auth/otp',
  async ({ state, dispatch, handleNextStep, navigate, handleClose, enqueueSnackbar }) => {
    try {
      const response = await post('/verify/otp/', state);
      // console.log(response);
      if (response) {
        useSetToken(response?.token);
        useSetRole(response?.role);
        sessionStorage.removeItem('user');
        if (enqueueSnackbar) {
          enqueueSnackbar(' OTP Verified Successfully!');
        }
        if (handleNextStep) {
          return handleNextStep();
        }

        if (handleClose) {
          handleClose();
        }
        if (navigate) {
          navigate(paths?.dashboard?.root, { replace: true });
        }

        // handleNextStep();
        return response;
      }
      // dispatch(activeSnack({ type: 'success', message: 'Successfully Authenticated ' }));

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getMemberProfile = createAsyncThunk(
  'user/profile',
  async ({ enqueueSnackbar, dispatch }) => {
    try {
      const response = await get(`/me`);
      if (response?._id) {
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const updateMemberProfile = createAsyncThunk(
  'user/profile/update',
  async ({ enqueueSnackbar, state }) => {
    try {
      const response = await put(`/user/update`, state);
      if (response?._id) {
        enqueueSnackbar('Admin Profile Updated Successfully', state);
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      // console.log(error);
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

// member login
export const memberLoginApi = createAsyncThunk(
  'auth/student/login',
  async ({ state, dispatch, navigate }) => {
    try {
      const response = await post('member/login', state);
      dispatch(activeSnack({ type: 'success', message: 'Successfully Authenticated ' }));
      if (response) {
        useSetToken(response?.token);
        useSetRole(response?.role);
        navigate(paths.dashboard?.root, { replace: true });
        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const ResentOtpApi = createAsyncThunk(
  'resend/otp',

  async ({ state, dispatch, handleNextStep, navigate, type }) => {
    try {
      const response = await post(`/resend/otp?type=${type || ''}`, state);
      // console.log(response);
      if (response) {
        // dispatch(
        //   activeSnack({
        //     type: 'success',
        //     message: '"An OTP has been successfully sent to your registered email.. ',
        //   })
        // );
        if (handleNextStep) {
          handleNextStep();
        }
        if (dispatch) {
          dispatch(
            activeSnack({
              type: 'success',
              message: 'An OTP has been successfully sent to your registered email.. ',
            })
          );
        }
        if (navigate) {
          navigate();
        }

        // handleNextStep();
        return response;
      }
      // dispatch(activeSnack({ type: 'success', message: 'Successfully Authenticated ' }));

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const EnquiryApi = createAsyncThunk('/enquiry/new', async ({ state, dispatch, reset }) => {
  try {
    const response = await post('/enquiry/new', state);

    if (response) {
      dispatch(activeSnack({ type: 'success', message: 'Successfully Submitted' }));
      if (reset) {
        return reset();
      }
      return response;
    }

    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

// create EnquiryApi
export const CreateEnquiryApi = createAsyncThunk(
  'enquiry/admin/new',
  async ({ state, dispatch, reset, setOpen }) => {
    try {
      const res = await post('/enquiry/admin/new', state);
      if (res) {
        dispatch(activeSnack({ type: 'success', message: 'Enquiry Created Successfully' }));
        if (reset) {
          dispatch(
            getAllEnquiry({
              page: 1,
              search: '',
              limit: '',
              dispatch,
              date: '',
            })
          );
          setOpen(false);
          return reset();
        }
        return res;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const SentOtpNewEmailApi = createAsyncThunk(
  'resend/new-mail/otp',

  async ({ state, dispatch, handleNextStep, handleClose, memberId }) => {
    try {
      const response = await post(`/member/admin/mail/update/${memberId}`, state);
      if (response) {
        if (handleNextStep) {
          handleNextStep();
        }
        if (dispatch) {
          dispatch(
            activeSnack({
              type: 'success',
              message: `Email updated successfully`,
            })
          );
        }
        if (handleClose) {
          handleClose();
        }

        // handleNextStep();
        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const VerifyNewEmailApi = createAsyncThunk(
  'new-mail/verify',

  async ({
    state,
    dispatch,
    setOpenUpdateMemberEmailSuccess,
    setActiveStep,
    handleClose,
    navigate,
    memberId,
  }) => {
    try {
      const response = await post(`/member/new-mail/verify/${memberId}`, state);
      if (response) {
        // if (dispatch) {
        //   dispatch(
        //     activeSnack({
        //       type: 'success',
        //       message: `An OTP has been successfully sent to your new email}`,
        //     })
        //   );
        // }
        dispatch(
          getMemberDetails({
            memberId,
            dispatch,
          })
        );
        if (navigate) {
          navigate();
        }
        if (handleClose) {
          handleClose();
        }
        if (setActiveStep) {
          setActiveStep(0);
        }
        if (setOpenUpdateMemberEmailSuccess) {
          setOpenUpdateMemberEmailSuccess(true);
        }
        // handleNextStep();
        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const NewMailUpdateVerifyOtpApi = createAsyncThunk(
  'mail-update/otp',
  async ({ state, dispatch, handleNextStep, reset, handleClose, enqueueSnackbar }) => {
    try {
      const response = await post('mail-update/verify/otp/', state);
      // console.log(response);
      if (response) {
        if (enqueueSnackbar) {
          enqueueSnackbar(' OTP Verified Successfully!');
        }

        if (handleNextStep) {
          return handleNextStep();
        }

        if (handleClose) {
          handleClose();
        }
        if (reset) {
          reset();
        }

        // handleNextStep();
        return response;
      }
      // dispatch(activeSnack({ type: 'success', message: 'Successfully Authenticated ' }));

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const ResentRegisterOtpApi = createAsyncThunk(
  'Register/resend/otp',

  async ({ state, dispatch, navigate }) => {
    try {
      const response = await post(`/member/v2/register`, state);
      // console.log(response);
      if (response) {
        if (dispatch) {
          dispatch(
            activeSnack({
              type: 'success',
              message: 'An OTP has been successfully sent to your registered email.. ',
            })
          );
        }
        if (navigate) {
          navigate(paths?.auth?.sign_up_otp, { replace: true });
        }

        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const verifyRegisterOtpApi = createAsyncThunk(
  'register/auth/otp',
  async ({ state, dispatch, navigate, enqueueSnackbar }) => {
    try {
      const response = await post('/verify/otp/', state);
      // console.log(response);
      if (response) {
        useSetToken(response?.token);
        useSetRole(response?.role);
        sessionStorage.removeItem('user');
        if (enqueueSnackbar) {
          enqueueSnackbar(' OTP Verified Successfully!');
        }

        if (navigate) {
          navigate(paths?.auth?.sign_up, { replace: true });
        }

        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const memberRegisterProfileApi = createAsyncThunk(
  'auth/member/register',
  async ({ state, dispatch, navigate }) => {
    try {
      const response = await post('/member/profile/new', state);

      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Successfully Registered' }));
        // navigate(`${paths?.auth?.login_otp_member}?register`);
        navigate(paths?.auth?.login_member);

        // useSetUser(JSON?.stringify(response));
        useSetToken(response?.token);

        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
