import * as Yup from 'yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { LoadingButton } from '@mui/lab';
import { Card, Stack, Alert, Container, Typography } from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { ResentRegisterOtpApi } from 'src/server/api/auth';

import Iconify from 'src/components/iconify';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

export default function SignUpView() {
  const isMobile = useResponsive('down', 'md');
  const location = useLocation();
  useEffect(() => {
    if (location?.search) {
      sessionStorage.setItem('refId', location?.search?.split('?ref_id=')[1]);
    }
  }, [location?.search]);
  const ReferralId = location?.search?.split('?ref_id=')[1];
  console.log(ReferralId);
  const router = useRouter();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading } = useSelector((state) => ({
    loading: state.auth.loading,
  }));
  const registerSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  });

  const defaultValues = {
    referral_id: ReferralId || '',
    // email: account?.email || '',
    // phone: account?.phone || '',
  };
  // console.log(selectedCountryCode);
  const methods = useForm({
    resolver: yupResolver(registerSchema),
    defaultValues,
  });

  const {
    // setValue,
    // reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const onSubmit = handleSubmit(async (data) => {
    const credentials = {
      state: {
        email: data?.email,
      },
      dispatch,
      navigate,
    };
    // console.log(credentials)
    dispatch(ResentRegisterOtpApi(credentials));
  });

  const HandleLogin = () => {
    router.push({
      pathname: paths?.auth?.login_member,
    });
  };
  const renderForm = (
    <>
      <RHFTextField
        name="email"
        type="email"
        label="Email"
        placeholder="Enter email address"
        InputLabelProps={{ shrink: true }}
        required
      />
      <Alert
        className="alert-custom"
        icon={<Iconify icon="material-symbols:info" sx={{ color: '#00A76F' }} />}
        sx={{
          bgcolor: '#F2F8F6',
          color: '#00A76F',
          borderRadius: '10px',
          display: 'inline-flex',
          alignItems: 'center',
          py: 1.5,
          px: 1.7,
          mt: 2,
          width: '100%',
        }}
      >
        <Typography
          color="#007867"
          sx={{
            fontSize: isMobile ? '11px' : '13px',
            fontWeight: 500,
            display: 'flex',
            gap: 0.5,
            alignItems: 'center',
          }}
        >
          You&apos;ll receive an OTP to this email.
        </Typography>
      </Alert>
      <Stack spacing={3} marginY={2}>
        <LoadingButton
          type="submit"
          size="large"
          variant="contained"
          sx={{ backgroundColor: '#007867', '&:hover': { backgroundColor: '#044f45' } }}
          color="success"
          loading={isSubmitting || loading}
        >
          Send OTP{' '}
        </LoadingButton>
      </Stack>
    </>
  );
  return (
    <Container maxWidth="lg">
      <MetaHelmet title="Winbuks" miniDescription="Member Registration" />

      <Stack maxWidth={420} marginX="auto">
        <Card variant="outlined" sx={{ p: 2, boxShadow: ' 0px 6px 2px 0px #E8ECEB' }}>
          <Stack alignItems="center" my={3} gap={1}>
            <Typography variant="h4" ontWeight={700}>
              Sign up to join Winbuks{' '}
            </Typography>
            <Typography variant="caption" fontWeight={400} color="#637381" display="flex">
              Provide your email to continue with the registration.
            </Typography>
          </Stack>
          <Stack p={isMobile ? 0 : 1} py={isMobile ? 3 : 3}>
            <FormProvider methods={methods} onSubmit={onSubmit}>
              {renderForm}
            </FormProvider>
            <Stack alignItems="center" textAlign="center">
              <Typography
                fontSize={isMobile ? 12 : 14}
                fontWeight={400}
                mt="14px"
                color="#637381"
                display="flex"
              >
                Already have an account?{' '}
                <Typography
                  fontSize={isMobile ? 12 : 14}
                  color="#007867"
                  ml="5px"
                  sx={{ cursor: 'pointer', textDecoration: 'underLine' }}
                  onClick={HandleLogin}
                >
                  Login
                </Typography>
              </Typography>
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </Container>
  );
}
