import PropTypes from 'prop-types';

import {
  Stack,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  InputAdornment,
} from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function WithdrawalRequestToolbar({
  onSearch,
  date,
  setDate,
  setPage,
  sortOrder,
  handleChangeSortOrder,
}) {
  const isMobile = useResponsive('down', 'md');

  const sortOrders = [
    { label: 'Latest', value: 'LATEST' },
    { label: 'Oldest', value: 'OLDEST' },
  ];

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Stack direction="row">
          <TextField
            name="date"
            label="Start Date"
            sx={{ width: '100%', minWidth: isMobile && 150, mr: 2 }}
            size="medium"
            type="date"
            variant="outlined"
            inputProps={{ maxLength: 4 }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setDate({
                ...date,
                from: e.target.value,
              });
              setPage();
            }}
          />
          <TextField
            name="date"
            label="End Date"
            sx={{ width: '100%', minWidth: isMobile && 147 }}
            size="medium"
            type="date"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setDate({
                ...date,
                to: e.target.value,
              });
              setPage();
            }}
          />
        </Stack>

        <Stack direction="row" flex={1}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="sort-label">Sort</InputLabel>
            <Select
              labelId="sort-label"
              id="sort-select"
              value={sortOrder}
              onChange={handleChangeSortOrder}
              color="primary"
            >
              {sortOrders.map((item) => (
                <MenuItem value={item?.value}>{item?.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        {!isMobile && (
          <TextField
            sx={{ width: '100%' }}
            onChange={(e) => {
              onSearch(e.target.value);
              setPage();
            }}
            placeholder="Search request ID"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ ml: 1, color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />
        )}
      </Stack>
      {isMobile && (
        <TextField
          sx={{ width: '100%', pt: isMobile && 1 }}
          onChange={(e) => {
            onSearch(e.target.value);
            setPage();
          }}
          placeholder="Search request ID"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ ml: 1, color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      )}
    </>
  );
}

WithdrawalRequestToolbar.propTypes = {
  onSearch: PropTypes.any,
  date: PropTypes.any,
  setDate: PropTypes.any,
  setPage: PropTypes.any,
  sortOrder: PropTypes.any,
  handleChangeSortOrder: PropTypes.any,
};
