import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { Typography } from '@mui/material';

export const getRemainingTime = (targetDate) => {
  const now = new Date();
  const target = new Date(targetDate);

  // Calculate the time difference in milliseconds
  const timeDiff = target.getTime() + 24 * 60 * 60 * 1000 - now.getTime();

  // If timeDiff is negative, the timer has expired
  if (timeDiff <= 0) {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  // Convert the time difference to hours, minutes, and seconds
  const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
  const seconds = Math.floor((timeDiff / 1000) % 60);

  return { hours, minutes, seconds };
};

const TimerComponent = ({ updatedAt, setDateCheck, setLoading = () => {} }) => {
  const [remainingTime, setRemainingTime] = useState(getRemainingTime(updatedAt));

  useEffect(() => {
    const timer = setInterval(() => {
      setLoading(true);
      const time = getRemainingTime(updatedAt);
      setRemainingTime(time);

      // Check if the timer has expired and update setDateCheck
      if (time.hours === 0 && time.minutes === 0 && time.seconds === 0) {
        setDateCheck(true);
        setLoading(false);
      }
    }, 1000);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedAt, setDateCheck]);

  // if (remainingTime?.hours <= 0) {
  //   setDateCheck(false);
  // }

  return (
    <Typography component="span" fontSize={13} fontWeight={600} color="#7A4100">
      {remainingTime.hours}hr {remainingTime.minutes}min {remainingTime.seconds}sec
    </Typography>
  );
};

// PropTypes for TimerComponent
TimerComponent.propTypes = {
  updatedAt: PropTypes.any,
  setLoading: PropTypes.any,
  setDateCheck: PropTypes.func, // Use func instead of any for setDateCheck
};

export default TimerComponent;
