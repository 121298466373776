import { useState } from 'react';
import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ListItemText from '@mui/material/ListItemText';
import { Stack, Tooltip, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { USDT } from 'src/hooks/use-USDT';

import { fDate, fTime } from 'src/utils/format-time';

import TextMaxLine from 'src/components/text-max-line/text-max-line';

import WithdrawalRequestApprove from './approveWithDrawal/ApproveRequest';
import WithdrawalRequestDetails from './approveWithDrawal/withdrawalRequistDetails';

// ----------------------------------------------------------------------

export default function WithdrawalReportTableRow({ row, selected, memberSingle }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { amount, wallet_address, request_id, approved_at, member } = row;

  return (
    <>
      <WithdrawalRequestApprove open={isOpen} setOpen={setIsOpen} isUpdate={row} />
      <WithdrawalRequestApprove
        open={isViewOpen}
        setOpen={setIsViewOpen}
        isUpdate={row}
        view="view"
      />
      <WithdrawalRequestDetails open={isOpenModal} setOpen={setIsOpenModal} isUpdate={row} />
      <TableRow hover selected={selected}>
        <TableCell>{request_id}</TableCell>

        <TableCell>
          {' '}
          <ListItemText
            primary={`${member?.name}`}
            secondary={`${member?.member_id}`}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>

        <TableCell>
          <TextMaxLine line={2} variant="body2" sx={{ maxWidth: 300 }}>
            {wallet_address || '--'}
          </TextMaxLine>
        </TableCell>

        <TableCell>
          <ListItemText
            primary={fDate(approved_at)}
            secondary={fTime(approved_at)}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>

        <TableCell sx={{ wordWrap: 'break-word' }}>
          <TextMaxLine line={2}>
            <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
              {amount.toFixed(2)} {USDT}
            </Typography>
          </TextMaxLine>
        </TableCell>

        <TableCell>
          <Stack flexDirection="row" alignItems="center">
            <Tooltip title="View Details" onClick={() => setIsViewOpen(true)}>
              <VisibilityIcon sx={{ fontSize: '20px', cursor: 'pointer', ml: 2 }} />
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
}

WithdrawalReportTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  memberSingle: PropTypes.bool,
};
