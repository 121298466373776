import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';

import { USDT } from 'src/hooks/use-USDT';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function MiniCards({ title, total, icon, color = 'primary', chart, sx, ...other }) {
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        width: '100%',
        px: 2,
        py: 0.6,
        borderRadius: 2,
        overflow: 'hidden',
        position: 'relative',
        color: 'common.white',
        bgcolor: `${color || 'secondary'}`,
        ...sx,
      }}
      {...other}
    >
      <Stack flexDirection="row" alignItems="center">
        <Iconify
          icon={icon}
          sx={{
            width: 20,
            // left: 32,
            height: 60,
            opacity: 1,
            // position: 'absolute',
          }}
        />
        <ListItemText
          sx={{ ml: 3, wordWrap: 'break-word', overflowWrap: 'anywhere' }}
          // primary={`  ${fNumber(total?.toFixed(2))} ${USDT}`}
          primary={`${total?.toFixed(2)} ${USDT}`}
          // secondary={title}
          primaryTypographyProps={{
            typography: 'subtitle1',
            component: 'span',
          }}
          // secondaryTypographyProps={{
          //   color: 'inherit',
          //   component: 'span',
          //   sx: { opacity: 0.64 },
          //   typography: 'caption',
          // }}
        />
      </Stack>
      <Stack>
        <Typography variant="caption" textAlign="center">
          {title}
        </Typography>
      </Stack>
    </Stack>
  );
}

MiniCards.propTypes = {
  chart: PropTypes.object,
  color: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  sx: PropTypes.object,
  title: PropTypes.string,
  total: PropTypes.number,
};
