import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
// import MemberToolbar from 'src/sections/Member/MemberToolbar';
import { Box } from '@mui/system';
// @mui
import {
  Card,
  Stack,
  Paper,
  Table,
  TableBody,
  TextField,
  Autocomplete,
  TableContainer,
  CircularProgress,
} from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { getAllMembers, getAllClubMembers } from 'src/server/api/member';

import Scrollbar from 'src/components/scrollbar';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

// sections
import ClubMemberSort from 'src/sections/ClubMember/ClubMemberSort';
import ClubMemberTableRow from 'src/sections/ClubMember/ClubMemberTableRow';

export default function ClubMemberLists() {
  const dispatch = useDispatch();
  const isMobile = useResponsive('down', 'md');

  const { clubMembersList, loading, membersList } = useSelector((state) => ({
    clubMembersList: state.member?.clubMembers,
    membersListCount: state.member.members,
    loading: state.member.loading,
    membersList: state.member.members?.members,
  }));

  console.log(membersList);
  const [selectedMember, setSelectedMember] = useState('');
  const [searchMember, setSearchMember] = useState('');

  const handleTextFieldChangeMember = (event) => {
    setSearchMember(event.target.value);
    setPage();
  };
  const handleAutocompleteChangeMember = (e, newValue) => {
    setSelectedMember(newValue || '');
    setPage();
  };
  const role = useGetRoles()?.role;

  const [tableData, setTableData] = useState([]);

  // const [searchText, setSearchText] = useState('');
  // const [page, setPage] = useState(1);
  // const total = membersListCount?.total;
  const identifier = 'member';

  // console.log(total);
  // const [limit, setLimit] = useState(10);
  const [date, setDate] = useState(10);

  // console.log(tableData);

  const table = useTable({ defaultOrderBy: 'createdAt', defaultRowsPerPage: 25 });

  useEffect(() => {
    setTableData(clubMembersList?.clubMembers);
  }, [clubMembersList]);
  const canReset = false;

  const notFound = (!tableData?.length && canReset) || !tableData?.length;
  const [sortValue, setSortValue] = useState('');

  const TABLE_HEAD = [
    { id: 'member_code', label: !sortValue ? 'Member ID - Club' : 'Member ID' },
    { id: 'name', label: 'Name' },
    { id: 'phone', label: 'Phone' },
    { id: 'email', label: 'Email' },
    { id: 'status', label: 'Status' },
    // { id: 'action', label: 'Action' },
  ];

  const handleDeleteClick = (memberDetails) => {
    setTableData(memberDetails);
  };

  const handleEditClick = (memberDetails) => {
    if (memberDetails) {
      setTableData(memberDetails);
    }
  };

  console.log(sortValue);
  const [state, setState] = useState({});

  // const handleSearch = (value) => {
  //   setSearchText(value);
  // };

  const totalPage = clubMembersList?.total;
  const limitValue = sessionStorage?.getItem(`rowsPerPage_${identifier}`);

  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      search: '',
      dispatch,
      limit: table?.rowsPerPage || limitValue,
      club: sortValue,
      member: selectedMember?.value,
      date,
      sort_order: '',
    };
    dispatch(getAllClubMembers(credentials));
  }, [dispatch, table.page, table?.rowsPerPage, limitValue, date, sortValue, selectedMember]);

  // const sortOrders = [
  //   { label: 'Latest', value: 'LATEST' },
  //   { label: 'Oldest', value: 'OLDEST' },
  // ];

  // const sortOrderOptions = [
  //   { label: 'All', value: '' },
  //   ...(sortOrders || []).map((results) => ({
  //     label: results?.label,
  //     value: results?.value,
  //   })),
  // ];

  // const handleChangeSortOrder = (e, newValue) => {
  //   setSortOrder(e.target.value);
  // };
  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchMember,
      limit: 5,
    };
    if (role === 'admin') {
      dispatch(getAllMembers(credentials));
    }
  }, [role, searchMember, dispatch]);

  const optionMember = [
    { label: 'All', value: '' },
    ...(membersList || []).map((results) => ({
      // label:`${results?.name}-${results?.member_id}`,
      label: `${results?.name}`,
      value: results?._id,
    })),
  ];
  const setPage = () => {
    table.setPage(0);
  };
  return (
    <>
      <MetaHelmet title="Winbuks" miniDescription="Club Members" />

      <CustomBreadcrumbs
        heading="Club Members"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },
          {
            name: 'Club',
            href: paths.dashboard.club,
          },
          {
            name: 'Members',
          },
        ]}
        sx={{
          mb: { xs: 3, md: 4 },
        }}
      />

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pb={3}
        className="member-sort"
        width="100%"
      >
        <ClubMemberSort
          sort={sortValue}
          setSort={setSortValue}
          state={state}
          setState={setState}
          memberCount={clubMembersList?.allCount}
          setPage={setPage}
        />
      </Stack>

      <Stack
        pb={2}
        spacing={2}
        width="100%"
        alignItems={{ xs: 'start', md: 'center' }}
        justifyContent=""
        direction={{
          xs: 'column',
          md: 'row',
        }}
      >
        <Stack direction="row" flex={1} padding="0px 0px 0px 0px">
          <TextField
            name="date"
            label="Start Date"
            sx={{ width: '100%', minWidth: isMobile && 160, mr: 2 }}
            size="medium"
            type="date"
            variant="filled"
            inputProps={{ maxLength: 4 }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setDate({
                ...date,
                start_date: e.target.value,
              });
              setPage();
            }}
          />
          <TextField
            name="date"
            label="End Date"
            sx={{ width: '100%', minWidth: isMobile && 165 }}
            size="medium"
            type="date"
            variant="filled"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setDate({
                ...date,
                end_date: e.target.value,
              });
              setPage();
            }}
          />
        </Stack>
        {/* <Stack direction="row" flex={1}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="sort-label">Sort</InputLabel>
            <Select
              labelId="sort-label"
              id="sort-select"
              value={sortOrder}
              onChange={handleChangeSortOrder}
              color="primary"
            >
              {sortOrders.map((item) => (
                <MenuItem value={item?.value}>{item?.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack> */}
        {/* <Stack flex={5}>
          <ClubMemberSearch setPage={setPage} onSearch={handleSearch} />
        </Stack> */}
        <Stack flex={1}>
          <Autocomplete
            id="country-select-demo"
            options={optionMember}
            autoHighlight
            size="medium"
            getOptionLabel={(option) => option.label}
            onChange={handleAutocompleteChangeMember}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Members"
                onChange={handleTextFieldChangeMember}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
        </Stack>
        <Stack flex={2}>{/* {jijj} */}</Stack>
      </Stack>

      {loading ? (
        <Paper
          sx={{
            textAlign: 'center',
          }}
        >
          <CircularProgress color="inherit" />
        </Paper>
      ) : (
        <Card>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) => {
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                );
              }}
            />
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={table.selected?.length}
                  onSort={table.onSort}
                />

                <TableBody>
                  {(Array.isArray(tableData) ? tableData : []).map((row, index) => (
                    <ClubMemberTableRow
                      sortValue={sortValue}
                      index={index}
                      page={table.page + 1}
                      rowsPerPage={table.rowsPerPage || 5 || limitValue}
                      key={row.id}
                      row={row}
                      selected={table?.selected?.includes(row.id)}
                      onSelectRow={() => table.onSelectRow(row.id)}
                      onEditRow={() => handleEditClick(row)}
                      onDeleteRow={() => handleDeleteClick(row.id)}
                      loading={loading}
                      colSpan={TABLE_HEAD?.length}
                    />
                  ))}
                </TableBody>

                {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                />  */}

                <TableNoData notFound={notFound} />
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={totalPage || 0}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
            identifier={identifier}
            customRowsPerPage={25}
          />
        </Card>
      )}
    </>
  );
}
